import React from 'react'
import {Link} from 'gatsby'
import {ResponsiveBarCanvas} from '@nivo/bar'
import {ResponsiveSankey} from '@nivo/sankey'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './donate.module.css'
import ngiLogo from '../images/ngi-zero-pet.svg'
import nlNetLogo from '../images/nlnet.svg'
import ngiPointerLogo from '../images/ngi-pointer.png'
import euFlagLogo from '../images/eu-flag.png'

function* range(from, to) {
  for (let i = from; i <= to; i++) {
    yield i
  }
}

function capitalize(str) {
  if (!str) return str
  else return str[0].toUpperCase() + str.substr(1)
}

// TODO: all the numbers on this page have to be converted to USD since we
// migrated our open collective to Open Source Collective which uses USD.
// This should be fixed in the ledger.csv using currency exchanges for the
// respective dates.

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

export const query = graphql`
  query {
    allLedgerCsv {
      edges {
        node {
          date
          handle
          type
          hours
          euros
          source
        }
      }
    }
    allAuthorsCsv {
      edges {
        node {
          handle
          name
          area
          picture
          linkTitle
          linkHref
        }
      }
    }
    allSponsorsCsv {
      edges {
        node {
          handle
          type
          name
        }
      }
    }
  }
`

const Backers = ({amount}) => (
  <>
    {Array.from(range(0, amount - 1)).map(i => (
      <a
        href={`https://opencollective.com/manyverse/backer/${i}/website`}
        key={`backer${i}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`https://opencollective.com/manyverse/backer/${i}/avatar.svg`}
          alt="Avatar of a backer on OpenCollective"
        />
      </a>
    ))}
  </>
)

const Sponsors = ({amount}) => (
  <>
    {Array.from(range(0, amount - 1)).map(i => (
      <a
        href={`https://opencollective.com/manyverse/sponsor/${i}/website`}
        key={`sponsor${i}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`https://opencollective.com/manyverse/sponsor/${i}/avatar.svg`}
          alt="Avatar of a sponsor on OpenCollective"
        />
      </a>
    ))}
    <div className={styles.miscSponsor}>
      <img src={ngiLogo} width={90} alt="NGI Zero Pet logo" />
    </div>
    <div className={styles.miscSponsor}>
      <img src={nlNetLogo} width={90} alt="NLnet logo" />
    </div>
    <div className={styles.miscSponsor}>
      <img src={ngiPointerLogo} width={90} alt="NGI Pointer logo" />
    </div>
    <div className={styles.miscSponsor}>
      <img src={euFlagLogo} width={90} alt="EU flag" />
    </div>
  </>
)

const OpenCollectiveSection = () => (
  <div className={styles.opencollective}>
    <div className={styles.ocText}>
      <h1>Join our OpenCollective</h1>
      <p>
        Together with dozens of other backers, you can become a backer or a
        sponsor on our OpenCollective page. You will also join a{' '}
        <strong>monthly newsletter exclusively for backers</strong>.
      </p>
      <p>
        Everything is transparent. The funds are used by{' '}
        <Link to="/team">our team</Link> to work primarily on app development,
        towards our <Link to="/roadmap">feature roadmap</Link>. The funds are
        utilized according to our public{' '}
        <a href="https://gitlab.com/staltz/manyverse/wikis/financial-plan">
          financial plan
        </a>{' '}
        and you can see the history of funds and statistics at the bottom of
        this page . The outcome of our work is seen on GitLab as{' '}
        <a href="https://gitlab.com/staltz/manyverse/commits">commits</a> and{' '}
        <a href="https://gitlab.com/staltz/manyverse/issues">issues</a>. You can
        also see the{' '}
        <a href="https://gitlab.com/staltz/manyverse/boards">issue board</a> to
        get a glance on work in progress and current priorities.
      </p>
      <p>
        The names of <strong>top 5 backers</strong> (based on total funds
        donated) are mentioned inside the app in the Thanks screen. This is
        updated monthly if the top 5 changes.
      </p>
      <a className={styles.btn} href="https://opencollective.com/manyverse">
        Become a backer
      </a>
    </div>
    <div style={{width: 80, height: 10}} />
    <div className={styles.ocBackers}>
      <h2>Backers</h2>
      <Backers amount={51} />
      <h2>Sponsors</h2>
      <Sponsors amount={3} />
    </div>
  </div>
)

function normalizeDate(dateStr) {
  return dateStr.replace(/(?<=\d\d\d\d-\d\d).*$/g, '')
}

const ChartsSection = ({data}) => {
  const entries = data.allLedgerCsv.edges.map(({node}) => node)
  const authors = data.allAuthorsCsv.edges.map(({node}) => node)
  const sponsors = data.allSponsorsCsv.edges.map(({node}) => node)
  const setOfSources = new Set(
    sponsors.filter(({type}) => type === 'source').map(({handle}) => handle)
  )
  const setOfBuckets = new Set(
    sponsors.filter(({type}) => type === 'bucket').map(({handle}) => handle)
  )
  const setOfAuthors = new Set(
    authors.map(({handle}) => handle)
  )

  const revenue = Array.from(
    entries
      .filter(entry => entry.type === 'received')
      .reduce((map, entry) => {
        const x = normalizeDate(entry.date)
        if (!map.has(x)) map.set(x, {date: x})
        const y = map.get(x)
        y[entry.source] = parseFloat(entry.euros)
        map.set(x, y)
        return map
      }, new Map())
      .values()
  )

  const hours = Array.from(
    entries
      .filter(
        ({type}) => type === 'worked_hours' || type === 'volunteered_hours'
      )
      .reduce((map, entry) => {
        const x = normalizeDate(entry.date)
        if (!map.has(x)) map.set(x, {date: x})
        const y = map.get(x)
        if (entry.type === 'worked_hours') {
          y.worked = (y.worked || 0) + parseFloat(entry.hours)
        }
        if (entry.type === 'volunteered_hours') {
          y.volunteered = (y.volunteered || 0) + parseFloat(entry.hours)
        }
        map.set(x, y)
        return map
      }, new Map())
      .values()
  )

  const people = Array.from(
    entries
      .filter(
        ({type}) => type === 'worked_hours' || type === 'volunteered_hours'
      )
      .reduce((map, entry) => {
        const x = entry.handle
        if (!map.has(x)) map.set(x, {id: x})
        const y = map.get(x)
        y.worked = y.worked || 0
        y.earned = y.earned || 0
        y.volunteered = y.volunteered || 0
        if (entry.type === 'worked_hours') {
          y.worked += parseFloat(entry.hours)
          y.earned += parseFloat(entry.euros)
        }
        if (entry.type === 'volunteered_hours') {
          y.volunteered += parseFloat(entry.hours)
        }
        y.total = y.worked + y.volunteered
        map.set(x, y)
        return map
      }, new Map())
      .values()
  ).sort((a, b) => b.worked + b.volunteered - (a.worked + a.volunteered))

  const hourlyrates = people
    .filter(person => person.earned)
    .map(person => ({
      ...person,
      hourlyrate: person.earned / person.worked,
    }))

  const moneyflow = {
    nodes: new Set(),
    links: new Map(),
  }
  entries.forEach(({handle, euros, source}) => {
    const actualEuros = parseFloat(euros)
    if (isNaN(actualEuros) || actualEuros <= 0) return
    if (!moneyflow.nodes.has(handle)) {
      moneyflow.nodes.add(handle)
    }
    if (!!source && !moneyflow.nodes.has(source)) {
      moneyflow.nodes.add(source)
    }
    const linkKey = `${source}->${handle}`
    if (!!source && !moneyflow.links.has(linkKey)) {
      moneyflow.links.set(linkKey, 0)
    }
    const linkEuros = moneyflow.links.get(linkKey)
    moneyflow.links.set(linkKey, linkEuros + actualEuros)
  })
  moneyflow.nodes = Array.from(moneyflow.nodes.values()).map(id => ({id}))
  moneyflow.links = Array.from(moneyflow.links.entries()).map(
    ([linkKey, value]) => ({
      source: linkKey.split('->')[0],
      target: linkKey.split('->')[1],
      value,
    })
  )

  const entities = []
    .concat(authors.map(author => [author.handle, author.name]))
    .concat(sponsors.map(sponsor => [sponsor.handle, sponsor.name]))
    .reduce((obj, [id, name]) => ({...obj, [id]: name}), {})

  return (
    <div className={styles.charts}>
      <h1>How much money has Manyverse received so far?</h1>
      <p>
        Manyverse was publicly launched on September 2018, coinciding with the
        launch of its Open Collective where backers were able to donate to the
        project. Manyverse has received some bigger grants in the past, and
        these have been sometimes distributed to team members performing
        subprojects for this project. The following chart shows incoming money
        over the months, separated (by color) per source.
      </p>
      <div style={{height: '300px'}}>
        <ResponsiveBarCanvas
          data={revenue}
          keys={[
            'backers',
            'ngizero',
            'sidn',
            'access',
            'ethereum_community',
            'ssbc_grants',
          ]}
          indexBy="date"
          groupMode="stacked"
          layout="vertical"
          maxValue={10000}
          enableGridX={false}
          enableGridY={true}
          gridYValues={[0, 2000, 4000, 6000, 8000, 10000]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legend: 'Date',
            legendPosition: 'middle',
            legendOffset: 60,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: [0, 2000, 4000, 6000, 8000, 10000],
            legend: 'Euros received',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          colors={['#12b886', '#20c997', '#38d9a9', '#63e6be', '#96f2d7']}
          tooltip={point =>
            `${financial(point.value)}€ from ${entities[point.id]}`
          }
          margin={{top: 50, right: 0, bottom: 70, left: 50}}
          isInteractive={true}
        />
      </div>

      <h1>How many hours has the Manyverse team worked?</h1>
      <p>
        Since its inception, many hours have been invested into the project
        without funds , but as grants and donations were received, this enabled
        development to be funded. The following chart shows in light blue how
        many hours were invested without funds ("volunteered"), and hour many
        hours were reimbursed ("worked").
      </p>
      <div style={{height: '300px'}}>
        <ResponsiveBarCanvas
          data={hours}
          keys={['worked', 'volunteered']}
          indexBy="date"
          groupMode="stacked"
          layout="vertical"
          maxValue={320}
          enableGridX={false}
          enableGridY={true}
          gridYValues={[0, 160, 320]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legend: 'Date',
            legendPosition: 'middle',
            legendOffset: 60,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: [0, 160, 320],
            legend: 'Hours',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          colors={['#4263eb', '#91a7ff', '#38d9a9', '#63e6be', '#96f2d7']}
          tooltip={point =>
            `${point.id === 'worked' ? 'Worked (funded)' : 'Volunteered'} ${
              point.value
            }h`
          }
          margin={{top: 50, right: 0, bottom: 70, left: 50}}
          isInteractive={true}
        />
      </div>

      <h1>How has the work been distributed?</h1>
      <p>
        The following chart shows how many hours each team member or contributor
        has put into the project.
      </p>
      <div style={{height: '300px'}}>
        <ResponsiveBarCanvas
          data={people}
          keys={['worked', 'volunteered']}
          indexBy="id"
          groupMode="grouped"
          layout="vertical"
          enableGridX={false}
          enableGridY={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Person',
            legendPosition: 'middle',
            legendOffset: 60,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Hours',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          colors={['#4263eb', '#91a7ff']}
          tooltip={point =>
            `${point.id === 'worked' ? 'Worked (funded)' : 'Volunteered'} ${
              point.value
            }h`
          }
          margin={{top: 50, right: 0, bottom: 70, left: 50}}
          isInteractive={true}
        />
      </div>

      <h1>How has money been distributed?</h1>
      <p>
        The sources and destinations of money can be seen in the chart below. On
        the left (in green) are the sources of project income. In the middle (in
        light blue) are intermediaries. On the right (in blue) are individuals
        receiving funds. Note that Open Collective charges three fees (host fee,
        platform fee, payment processor fee), so it is also a destination of a
        small percentage of funds. There may be some discrepancies here because
        of currency conversions, because some funds were received in USD and
        some received in ETH (during months when its value fluctuated a lot),
        but the displayed currency is EUR.
      </p>
      <div style={{height: '300px'}}>
        <ResponsiveSankey
          data={moneyflow}
          margin={{top: 0, right: 0, bottom: 0, left: 0}}
          align="justify"
          nodeOpacity={1}
          nodeThickness={20}
          nodeInnerPadding={0}
          nodeSpacing={6}
          nodeBorderWidth={0}
          linkOpacity={0.4}
          linkHoverOthersOpacity={0.1}
          enableLinkGradient={true}
          nodeTooltip={point => capitalize(entities[point.id])}
          linkTooltip={point =>
            `${point.source.id} gave ${financial(point.value)}€ to ${
              point.target.id
            }`
          }
          colors={node => {
            const GREEN = '#12b886'
            const BLUE = '#72c3fc'
            const INDIGO = '#4263eb'
            if (setOfSources.has(node.id)) return GREEN
            if (setOfBuckets.has(node.id)) return BLUE
            if (setOfAuthors.has(node.id)) return INDIGO
            return INDIGO
          }}
          labelPosition="inside"
          labelOrientation="horizontal"
          labelPadding={16}
          labelTextColor={{from: 'color', modifiers: [['darker', 1]]}}
          animate={true}
          motionStiffness={140}
          motionDamping={13}
        />
      </div>

      <h1>How much has each team member earned per hour?</h1>
      <p>
        The following chart displays the average hourly rate for each team
        member who has worked at least once on Manyverse. It's calculated as
        "total earned" / "total hours worked".
      </p>
      <div style={{height: '300px'}}>
        <ResponsiveBarCanvas
          data={hourlyrates}
          keys={['hourlyrate']}
          indexBy="id"
          enableGridX={false}
          enableGridY={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Person',
            legendPosition: 'middle',
            legendOffset: 60,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: [0, 10, 20, 30, 40, 50],
            legend: 'Euros / hour',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          colors={['#38d9a9']}
          gridYValues={[0, 10, 20, 30, 40, 50]}
          tooltip={point => `Avg. ${financial(point.value)}€/h`}
          margin={{top: 50, right: 0, bottom: 70, left: 50}}
          isInteractive={true}
        />
      </div>

      <h1>Read more details</h1>
      <p>
        The data above is recorded on{' '}
        <a href="https://gitlab.com/staltz/manyverse-site/-/raw/master/src/data/ledger.csv">
          this CSV file
        </a>{' '}
        found in our repository.
      </p>
    </div>
  )
}

const DonatePage = ({data}) => {
  return (
    <>
      <Layout>
        <SEO title="Donate" />
        <OpenCollectiveSection />
        <ChartsSection data={data} />
      </Layout>
    </>
  )
}

export default DonatePage
